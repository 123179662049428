<template>
  <section>
    <!-- <kn-select
      label="Institución educativa"
      v-model="model.institucion_educativa"
      :loading="loading"
      :rules="[rules.required]"
      :items="institutions"
      item-text="nombre_comercial"
      item-value="id"
      :disabled="disabled"
      @input="fullArraysByIE(model.institucion_educativa)"
    /> -->
    <kn-select
      v-if="showUserType"
      label="Tipo de usuario"
      v-model="model.id_tipo_usuario"
      :loading="loading"
      :rules="[rules.required]"
      :isMandatory="true"
      :items="userTypes"
      item-text="tipo_usuario"
      item-value="id"
      :disabled="disabled"
    />
    <kn-select
      label="Tipo de empleado"
      v-model="model.tipo_empleado"
      :loading="loading"
      :rules="[rules.required]"
      :items="employeeTypes"
      item-text="dato"
      item-value="id"
      :disabled="disabled"
    />
    <kn-select
      label="Asignar grupo"
      v-model="model.grupo"
      :loading="loading"
      :rules="[]"
      :isMandatory="false"
      :items="groups"
      item-text="nombre_grupo"
      item-value="id"
      :disabled="disabled"
    />
    <kn-check-box
      class="mt-0"
      label="Titular de grupo"
      v-model="model.titular_grupo"
      :loading="loading"
      :rules="[]"
      :isMandatory="false"
      :disabled="disabled"
    />
  </section>
</template>

<script>
import { getItem } from '@/api/api-methods';
import { validationFormMixin } from '../../../modules/shared/mixins/validationFormMixin';
import KnSelect from '../../shared/components/KnSelect.vue';
import KnCheckBox from '../../shared/components/KnCheckBox.vue';
import { mapState } from 'vuex';
export default {
  components: { KnSelect, KnCheckBox },
  mixins: [validationFormMixin],
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showUserType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      row: null,
      institutions: [],
      groups: [],
      employeeTypes: [],
      userTypes: [],
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    // const institutionsRes = await getItem(
    //   '/app-personas/institucion-educativa?limit=50'
    // );
    // this.institutions =
    //   institutionsRes && institutionsRes.results ? institutionsRes.results : [];
    await this.fullArraysByIE(this.institutionId);
    this.loading = false;
  },
  computed: {
    ...mapState(['institutionId']),
  },
  methods: {
    async fullArraysByIE(institutionId) {
      const groupRes = await getItem(
        `/app-personas/filters/grupo?institucion_educativa=${institutionId}&limit=1000&estatus_sistema=true`
      );
      const employeeRes = await getItem(
        `/app-personas/filters/tipo-empleado?institucion_educativa=${institutionId}&limit=1000&estatus_sistema=true`
      );

      this.groups = groupRes && groupRes.results ? groupRes.results : [];
      this.employeeTypes =
        employeeRes && employeeRes.results ? employeeRes.results : [];

      if (this.showUserType) {
        const userTypesRes = await getItem(
          '/app-land/filters/tipo-usuario?limit=1000&estatus_sistema=true'
        );
        this.userTypes =
          userTypesRes && userTypesRes.results ? userTypesRes.results : [];
      }
    },
  },
};
</script>

<style></style>
